<template>
  <div class="courseware-preview-container">
    <div class="courseware-preview-main">
      <div class="courseware-preview-player">
        <Player ref="player" @beginVideo="beginVideo"  @playEnd="endVideo" @updateTime="updateTime" :clickCoverPlay="true" :isShowButton="false" :isShowCover="true"></Player>
      </div>
      <div class="courseware-preview-progress">
        <el-slider size="large" :show-tooltip="false" @input="seek" :max="getCourseware()?.play_video_time || 100" v-model="videoTime" />
      </div>
      <div class="courseware-preview-time">
        <span>{{ currVideoTime }}</span>
        <span>{{ maxCoursewareTime }}</span>
      </div>
      <div class="courseware-preview-control">
        <img :src="require('@/assets/images/live/prep.png')" @click="prepVideo" :class="{hidden:isFisrt()}"/>
        <img :src="require('@/assets/images/live/pause.png')" @click="pauseVideo" v-if="isPlaying"/>
        <img :src="require('@/assets/images/live/play.png')" @click="playVideo" v-else/>
        <img :src="require('@/assets/images/live/next.png')" @click="nextVideo" :class="{hidden:isLast()}"/>
      </div>
    </div>
    <div class="courseware-preview-button global_container_center" v-if="!isPreview&&isSelect()">
      <CustomButton size="middle" :class="{'courseware-preview-button-select':isUse()}"  @click="doSelect" round>{{ isUse()?"已选择":"选择"}}</CustomButton>
    </div>
  </div>
</template>
<script>
import Player from '@/components/player/Player'
import { VideoPause,CaretLeft,CaretRight,VideoPlay } from "@element-plus/icons"
import CustomButton  from '@/components/unit/CustomButton'

export default {
  name:"courseware-preview",
  components:{
    Player,
    VideoPlay,
    CustomButton,
    VideoPause,
    CaretLeft,
    CaretRight,

  },
  data(){
    return {
      config:{
        coursewares:[],
        allUse:[]
      },
      iconSize:36,
      videoTime:0,
      currCoursewareId:0,
      isPlaying:false,
      isLoading:false,
      isPreview:false,
      timer:null
    }
  },
  computed:{
    currVideoTime(){
      return this.$tools.getShowTime(this.videoTime*1000,"mm:ss")
    },
    maxCoursewareTime(){
      if(this.config.coursewares.length == 0) return ""
      return this.$tools.getShowTime(this.getCourseware().play_video_time*1000,"mm:ss")
    }
  },
  created(){
    this.currCoursewareId = Number(this.$route.query.coursewareId)
    this.isPreview = this.$route.query.preview&&this.$route.query.preview != 0
    this.loadCourseware()
    if(!this.isPreview&&this.isSelect()){
      this.loadClassUse()
    }
  },
  methods:{
    // calcVideoTime(){
    //   if(this.timer){
    //     clearTimeout(this.timer)
    //     this.timer = null
    //   }
    //   if(!this.isPlaying) return;
    //   this.videoTime += 1
    //   setTimeout(()=>{
    //     this.calcVideoTime()
    //   },1000)
    // },
    isSelect(){
      return !!Number(this.$route.query.select)
    },
    isUse(){
      return this.config.allUse.includes(this.currCoursewareId)
    },
    isFisrt(){
      if(this.config.coursewares.length == 0) return false
      let courseware = this.config.coursewares.slice(0,1)[0]
      return  courseware.id == this.currCoursewareId
    },
    isLast(){
      if(this.config.coursewares.length == 0) return false
      let courseware = this.config.coursewares.slice(-1)[0]
      // console.log(courseware)
      return  courseware.id == this.currCoursewareId
    },
    updateTime(time){
      this.videoTime = time;
    },
    seek(e){
      this.$refs.player.seek(e)
    },
    beginVideo(){
      this.isPlaying = true
      // this.calcVideoTime()
    },
    endVideo(){
      this.isPlaying = false
      this.videoTime = this.getCourseware().play_video_time
    },

    prepVideo(){
      let index = this.config.coursewares.findIndex(courseware=>courseware.id==this.currCoursewareId)
      if(index <= 0) return;
      let courseware = this.config.coursewares[index-1]
      this.currCoursewareId = courseware.id
      this.switch()
    },
    nextVideo(){
      let index = this.config.coursewares.findIndex(courseware=>courseware.id==this.currCoursewareId)
      if(index >= this.config.coursewares.length-1) return;
      let courseware = this.config.coursewares[index+1]
      this.currCoursewareId = courseware.id
      this.switch()
    },
    playVideo(){
      this.$refs.player.play(true)
    },
    pauseVideo(){
      this.$refs.player.pause()
      this.isPlaying = false
    },
    getCourseware(){
      return this.config.coursewares.find(item=>item.id == this.currCoursewareId)
    },
    loadCourseware(){
      let url = this.$tools.getURL(this.$urls.admin.courseware, { doctor_id: this.$route.query.doctorId||"" });
      this.$axios.get(url).then(res=>{
        if(!this.isSelect()) this.config.coursewares = res.data.filter(item=>item.id == this.$route.query.coursewareId)
        else {
          this.config.coursewares = res.data
        }
        this.switch()
      })
    },
    loadClassUse(){
      let url = this.$tools.getURL(this.$urls.admin.haveCourseware,{id:this.$route.query.doctorId})
      this.$axios.get(url).then(res=>{
        this.config.allUse = res.data.map(x=>x.id)
      })
    },
    switch(){
      this.$nextTick(()=>{
        let courseware = this.config.coursewares.find(item=>item.id == this.currCoursewareId)
        this.$refs.player.switch(courseware.play_video_url,'video/mp4')
        this.$refs.player.setCover(courseware.cover)
        this.isPlaying = false
        this.videoTime = 0;
      })
    },
    doSelect(){
      let data = this.config.coursewares.find(item=>item.id==this.currCoursewareId)
      if(this.config.allUse.includes(data.id) || this.isLoading) {
        this.$tips.error({ text: "每个课件只可选择一次，请选择其他课件" })
        return;
      }
      this.$confirm("课件一旦确认选择，不可更改，请确认是否选择该课件","提示",{
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            center: true,
            type: "warning",
            customClass: "coursewares_confirm_customClass",
        }).then(()=>{
            this.config.allUse.push(data.id)
            if(this.$route.query.type == 0){
              let meetingInfo = this.$tools.getLocalCookie("meetingInfo")
              meetingInfo.courseware_id = data.id
              this.$tools.setLocalCookie("meetingInfo",meetingInfo)
              this.$router.push({
                path:'/meetinfo',
                query:this.$route.query
              })
            }else{
              this.doCompleteCourseware(data.id)
            }
        }).catch(() => {});
      },
      doCompleteCourseware(id){
        this.isLoading = true
        this.$axios.post(this.$urls.admin.bindCourseware,{id:this.$route.query.meetId,courseware_id:id}).then(res=>{
          setTimeout(()=>{this.isLoading = false},1000)
          this.$router.push({
            path:'/meetlist'
          })
        }).catch(res=>{
          setTimeout(()=>{this.isLoading = false},1000)
        })
      },
  }
}
</script>
<style>
.courseware-preview-container{
  display: flex;
  width:100%;
  height:100%;
  flex-direction: column;
  /* padding:4vw; */
  background:#f2f2f2
}
.courseware-preview-player{
  height:100vw;
  width: 100vw;
}
.courseware-preview-main{
  display: flex;
  flex-direction: column;
  width:100vw;
  background:#fff;
  border-bottom-left-radius: 4vw;
  border-bottom-right-radius: 4vw;
}
.courseware-preview-progress{
  padding:2vw 8vw 0;
}
.courseware-preview-time{
  padding:0 4vw 4vw;
  display: flex;
  justify-content: space-between;
}
.courseware-preview-control{
  padding:2vw 20vw;
  display: flex;
  justify-content: space-between;
}
.courseware-preview-control img{
    width: 4vw;
    height: auto;
}
.courseware-preview-button{
  margin-top:8vw;
}
.courseware-preview-button .customButton_container{
  width:30vw;
}
.courseware-preview-button-select{
  background:#bfbfbf;
}
.hidden{
  visibility: hidden;
}
.coursewares_confirm_customClass {
  width: 90%;
}
.coursewares_confirm_customClass .el-message-box__message,.coursewares_confirm_customClass .el-message-box__title {
  font-size: 4vw;
}
</style>